import React, { Component } from 'react'

import Map from '../components/map'

class Contact extends Component {

  render() {
    let { title, content, findUs, chatToUs } = this.props
    return (
      <section className="basic">
        <div className="basic_inner container">
          <div className="basic__row"> 
            <h1 className="basic__title">{title}</h1>
            <div className="basic__content">
              <div className="basic__blurb" dangerouslySetInnerHTML={{ __html: content }} />
              <div className="basic__contact">
                <div className="basic__find">
                  <h3>Find us</h3>
                  <p dangerouslySetInnerHTML={{ __html: findUs }} />
                </div>
                <div className="basic__chat">
                  <h3>Chat to us</h3>
                  <p dangerouslySetInnerHTML={{ __html: chatToUs }} />
                </div>
              </div>
            </div>
          </div>
          <Map />
        </div>
      </section>
    )
  }
}

Contact.defaultProps = {
  title: `So let’s connect?`,
  content: `<p>We might be clever with numbers but we know we’re in the business of people. We love meeting new people, understanding who they are and what we can do together.</p>`,
}

export default Contact