import React, { Component } from 'react'
import { Link } from 'gatsby'
import { ArrowDown } from './icons.js'
import Fade from 'react-reveal/Fade'

class Intro extends Component {

  componentDidMount() {
    let scroll = require('smooth-scroll')
    scroll('a[href*="#"]', { offset: 120, updateURL: false })
  }

  render() {
    let { title, subtitle, image, scroll, column1, column2, buttonText, buttonLink } = this.props
    return (
      <section className="intro">
        <div className="intro__inner container">
          <div className="intro__top">
            <div className="intro__left">
              <Fade left distance="30px">
                <h1 dangerouslySetInnerHTML={{ __html: title }} />
              </Fade>
              <div className="intro__knowwhy">
                <a className='mobile' href="#bottom-1"><ArrowDown /> {scroll}</a>
                <a className='desktop' href="#bottom-2"><ArrowDown /> {scroll}</a>
              </div>
              <h2 className="intro__title" id="bottom-2">{subtitle}</h2>
            </div>
            <div className="intro__right">
              { image && <img src={image.localFile.childImageSharp.fixed.src} alt="Seiva" /> }
            </div>
          </div>
        </div>
        <div className="intro__bottom">
          <div className="container">
            <div className="intro__bottomleft">
              <h2 className="intro__title" id='bottom-1'>{subtitle}</h2>
              <Link className="btn btn--border" to={buttonLink}>{buttonText}</Link>
            </div>
            <div className="intro__bottomright">
              <div className="intro__bottominfoleft"><p dangerouslySetInnerHTML={{ __html: column1 }} /></div>
              <div className="intro__bottominforight"><p dangerouslySetInnerHTML={{ __html: column2 }} /></div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Intro
