import React, { Component } from 'react'
import Slider from 'react-slick'

import left from '../assets/images/icon/left-arrow.svg'
import right from '../assets/images/icon/left-arrow.svg'

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className}>
      <img
        src={right}
        style={{ ...style }}
        alt='next gallery item'
        onClick={onClick}
      />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className}>
      <img
        src={left}
        style={{ ...style }}
        alt='prev gallery item'
        onClick={onClick}
      />
    </div>
  );
}

class Testimonials extends Component {

  state = {
    activeSlide: 0,
  }
  
  render() {
    let { title, subtitle, testimonials } = this.props

    const settings = {
      infinite: true,
      speed: 500,
      autoplay: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      cssEase: 'ease-in-out',
      pauseOnHover: false,
      focusOnSelect: false,
      draggable: false,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    }

    return (
      <section className="testimonials">
        { title &&
          <div className='services__top'>
            <div className='services__statement'>
              <h2 dangerouslySetInnerHTML={{ __html: title }} />
            </div>
            <div className='services__subheading'>
              <h3>{subtitle}</h3>
            </div>
          </div>
        }
        { testimonials &&
          <Slider className="testimonials__items" ref={slider => (this.slider = slider)} {...settings}>
            { testimonials.map((el, i) => (
                <div className="testimonials__item" key={i}>
                  <div className="testimonials__bg" style={{backgroundColor: el.acf.backgroundColour}} />
                  { el.acf.image && <img src={el.acf.image.localFile.childImageSharp.fixed.src} alt={el.acf.name} /> } 
                  <p className="testimonials__quote">{el.acf.quote}</p>
                  <span className="testimonials__website">{el.acf.website}</span>
                  <span className="testimonials__company">{el.acf.name}</span>
                </div>
              ))
            }
          </Slider>
        }
      </section>
    )
  }
}

export default Testimonials