import React, { Component } from 'react'
import { Link } from 'gatsby'
import he from 'he'
import Slider from 'react-slick'
import Fade from 'react-reveal/Fade'

import RenderIcon from '../utils/render-icon'
import left from '../assets/images/icon/left-arrow.svg'
import right from '../assets/images/icon/left-arrow.svg'

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{ ...style }}
      src={right}
      alt='next gallery item'
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{ ...style }}
      src={left}
      alt='last gallery item'
      onClick={onClick}
    />
  );
}

class Services extends Component {

  state = {
    activeSlide: 0,
  }

  render() {

    let { title, subtitle, services } = this.props

    const settings = {
      infinite: false,
      speed: 500,
      autoplay: false,
      dots: false,
      arrows: true,
      cssEase: 'ease-in-out',
      pauseOnHover: false,
      focusOnSelect: false,
      draggable: false,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          },
        },
      ],
    }

    return (
      <section className='services'>
        <div className='services__inner container'>
          <div className='services__top'>
            <div className='services__statement'>
              <h2 dangerouslySetInnerHTML={{ __html: title }} />
            </div>
            <div className='services__subheading'>
              <h3>{subtitle}</h3>
            </div>
          </div>
          <Fade bottom distance="30px">
            <Slider className="services__bottom" ref={slider => (this.slider = slider)} {...settings}>
              { services.map((el, i) => (
                <div className='services__item' key={i}>
                  <div className='services__image'>
                    { RenderIcon(el.acf.icon) }
                  </div>
                  <h3>{ he.decode(el.acf.shortTitle) }</h3>
                  <div className='services__text' dangerouslySetInnerHTML={{ __html: el.acf.shortDescription }} />
                  <Link to={`/service/${el.slug}`} className='btn btn--border'>Learn More</Link>
                </div>
              ))}
            </Slider>
          </Fade>
        </div>
      </section>
    )
  }
}

Services.defaultProps = {
  title: `Whatever your it* is, our mission is to help make it* happen.`,
  subtitle: `Here’s how we can help.`,
  services: {},
}

export default Services
