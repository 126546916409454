import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class OurWhy extends Component {

  render() {
    let { ourWhyTitle, ourWhyContent, ourHowTitle, ourHowContent, ourWhatTitle, ourWhatContent, image } = this.props
    return (
      <section className="our-why">
        <div className="our-why_inner container">
          <div className="our-why__row our-why__row--why"> 
            <Fade left distance="30px">
              <h3 className="our-why__title">{ourWhyTitle}</h3>
            </Fade>
            <Fade bottom distance="30px">
              <div className="our-why__content" dangerouslySetInnerHTML={{ __html: ourWhyContent }} />
            </Fade>
          </div>
          <div className="our-why__image">
            <Fade left distance="30px">
              { image && <img src={image.localFile.childImageSharp.fixed.src} alt="Seiva - Our Why" /> } 
            </Fade>
          </div>
          <div className="our-why__row our-why__row--how"> 
            <Fade left distance="30px">
              <h3 className="our-why__title">{ourHowTitle}</h3>
            </Fade>
            <Fade bottom distance="30px">
              <div className="our-why__content" dangerouslySetInnerHTML={{ __html: ourHowContent }} />
            </Fade>
          </div>
          <div className="our-why__row our-why__row--what">
            <Fade left distance="30px"> 
              <h3 className="our-why__title">{ourWhatTitle}</h3>
            </Fade>
            <Fade bottom distance="30px">
              <div className="our-why__content" dangerouslySetInnerHTML={{ __html: ourWhatContent }} />
            </Fade>
          </div>
        </div>
      </section>
    )
  }
}

export default OurWhy