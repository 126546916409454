import React from 'react'
import Slider from 'react-slick'

import Fade from 'react-reveal/Fade'

import RenderIcon from '../utils/render-icon'
import left from '../assets/images/icon/left-arrow.svg'
import right from '../assets/images/icon/right-arrow.svg'

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{ ...style }}
      src={right}
      alt='next gallery item'
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{ ...style }}
      src={left}
      alt='last gallery item'
      onClick={onClick}
    />
  );
}


const ImageGallery = ({ images }) => {
  const settings = {
    infinite: false,
    speed: 500,
    autoplay: false,
    dots: false,
    arrows: true,
    cssEase: 'ease-in-out',
    pauseOnHover: false,
    focusOnSelect: false,
    //draggable: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        },
      },
    ],
  }
  return (
    <section className="image-gallery">
      <div className="image-gallery__inner">
        <Fade bottom distance="30px">
          <Slider className="image-gallery__slider" {...settings}>
            {images.map((image, i) => (
              <img src={image.localFile.childImageSharp.fixed.src} key={i} />
            ))}
          </Slider>
        </Fade>
      </div>
    </section>
  )
}

export default ImageGallery