import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import encode from '../utils/encode'

class DownloadGuide extends Component {

  state = {
    first_name: '',
    last_name: '',
    email: '',
    company: '',
    company_size: '',
    subscribe: false,
    formActive: false,
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
          "form-name": form.getAttribute("name"),
          ...this.state
      })
    })
      .then(() => this.setState({result: 'success'}))
      .catch(error => this.setState({result: 'fail'}))

    if ( this.state.subscribe === 'on' ) {
      addToMailchimp(this.state.email, {
        FNAME: this.state.frist_name,
        LNAME: this.state.last_name
      })
    }
  }

  _showForm = () => {
    this.setState({ formActive: true })
  }

  render() {
    let { data } = this.props
    const settings = data.wp.siteSettings.acf
    let { formActive } = this.state
    return (
      <section className="download">
        <div className="download__inner container">
          <div className="download__image">
            <Fade left distance="30px">
              { settings.downloadGuideImage && <img src={settings.downloadGuideImage.localFile.childImageSharp.fixed.src} alt="Seiva - Download Seiva's Annual Guide to Better Business" /> }
            </Fade>
          </div>
          <div className={formActive ? 'download__content active' : 'download__content'}>
            <Fade right distance="30px">
              <div className="download__initial">
                <h2>{settings.downloadGuideTitle}</h2>
                <div className="download__blurb">
                  <p dangerouslySetInnerHTML={{ __html: settings.downloadGuideContent }} />
                </div>
                <button className="btn btn--border" onClick={() => this._showForm()}>Get guide</button>
              </div>
            </Fade>
            <div className="download__form">
              { this.state.result === 'success' &&
                <h3 dangerouslySetInnerHTML={{ __html: settings.download_guideConfirmationMessage }} />
              }
              { this.state.result !== 'success' &&
                <form
                  className="form form--download" 
                  name="download"
                  method="post"
                  onSubmit={this.handleSubmit}>
                  <input type="hidden" name="form-name" value="download" />
                  <div className="form__row">
                    <input type="text" name="first_name" placeholder="First Name" onChange={this.handleChange} required /> 
                  </div>
                  <div className="form__row">
                    <input type="text" name="last_name" placeholder="Last Name" onChange={this.handleChange} required /> 
                  </div>
                  <div className="form__row">
                    <input type="email" name="email" placeholder="Email" onChange={this.handleChange} required /> 
                  </div>
                  <div className="form__row">
                    <input type="text" name="company" placeholder="Company name" onChange={this.handleChange} required /> 
                  </div>
                  <div className="form__row">
                    <input type="text" name="company_size" placeholder="Company size (optional)" onChange={this.handleChange} /> 
                  </div>
                  <div className="form__row form__row--checkbox">
                    <label htmlFor="subscribe">
                      <input type="checkbox" id="subscribe" name="subscribe" onChange={this.handleChange} />
                      Yes, I want more business insights. Sign me up!
                    </label>
                  </div>
                  <div className="form__row form__row--submit">
                    <button className="btn btn--border" type="submit">Download guide</button>
                  </div>
                </form>
              }
            </div>
          </div>
        </div>
      </section>
    )
  }
}

const GuideData = (props) => (
  <StaticQuery
    query={graphql`
      query {
        wp {
          siteSettings {
            acf {
              downloadGuideImage {
                localFile {
                  childImageSharp {
                    fixed(width: 1000) {
                      src
                    }
                  }
                }
              }
              downloadGuideTitle
              downloadGuideContent
              downloadGuideConfirmationMessage
            }
          }
        }
      }
    `}
    render={data => <DownloadGuide data={data} {...props} />}
  />
)

export default GuideData