import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import { Link } from 'gatsby'

class Cta extends Component {

  render() {
    let { title, buttonText, buttonLink } = this.props
    return (
      <section className="cta">
        <div className="cta__inner container">
          <div className="cta__title">
            <Fade left distance="30px">
              <p dangerouslySetInnerHTML={{ __html: title }}></p>
            </Fade>
          </div>
          <Fade right distance="30px">
            <Link className="btn btn--dark cta__button" to={buttonLink}>{buttonText}</Link>
          </Fade>
        </div>
      </section>
    )
  }
}

export default Cta