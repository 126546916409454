import React, { Component } from 'react'

import TeamCard from './cards/team'

class Team extends Component {

  render() {
    let { title, content, team } = this.props
    return (
      <section className="team">
        <div className="team__inner container">
          <div className="team__row">
            <h3 className="team__title">{title}</h3>
            <div className="team__content" dangerouslySetInnerHTML={{ __html: content }} />
          </div>
          <div className="team__items">
            { team.map((el, i) => <TeamCard {...el} key={i} />) }
          </div>
        </div>
      </section>
    )
  }
}

Team.defaultProps = {
  title: `Our Team`,
  content: `<p>The faces behind SEIVA; each as expertly brilliant ?and hardworking as the next.</p>`
}

export default Team