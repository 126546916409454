import React, { Component } from "react"

import encode from "../utils/encode"

import Fade from "react-reveal/Fade"

class FeedbackForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      reason: "",
      rating: "",
      email: "",
      isSubmitted: false,
    }
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location?.search)

    this.setState({
      rating: params.get("rating"),
      email: params.get("email"),
    })
  }

  handleInputChange = (e) => {
    const target = e.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    const form = e.target

    fetch("https://hooks.zapier.com/hooks/catch/2181815/3zv8ab5/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        "timestamp": new Date().toISOString(),
        ...this.state,
      }),
    })

    this.setState({
      isSubmitted: true,
    })
  }

  render() {
    if (this.state.isSubmitted)
      return (
        <section className="feedback-form feedback-form__submitted">
          <Fade bottom distance="30px" delay={250}>
            <p className="subtext">Thank you for submitting!</p>
          </Fade>
          <Fade bottom distance="30px" delay={500}>
            <p className="small">
              <span className="circle" /> Your message has been sent
            </p>
          </Fade>
        </section>
      )

    return (
      <section className="feedback-form">
        <Fade top distance="30px" delay={250}>
          <p className="small">
            <span className="circle" /> Tell us more
          </p>
        </Fade>
        <Fade bottom distance="30px">
          <h1 className="title">{this.props.title}</h1>
        </Fade>
        <Fade bottom distance="30px" delay={250}>
          <p className="subtext">What is the main reason for your rating?</p>
          <p className="subtext small">
            Please include your first and last name
          </p>
        </Fade>
        <Fade bottom distance="30px" delay={500}>
          <form onSubmit={this.handleSubmit} className="form">
            <textarea
              name="reason"
              id="reason"
              className="text"
              value={this.state.reason}
              onChange={this.handleInputChange}
            />
            <button type="submit" className="btn">
              Submit
            </button>
          </form>
        </Fade>
      </section>
    )
  }
}

export default FeedbackForm
