import React from 'react'

const DoubleImage = ({ leftImage, rightImage, leftImageHover, rightImageHover }) => {

  return (
    <section className="double-image">
      <div className="double-image__inner container">
        <div className='double-image__image'>
          { leftImage?.localFile?.childImageSharp?.fixed.src && <img className="double-image__image-main" src={leftImage.localFile.childImageSharp.fixed.src} alt="Seiva" /> }
          { leftImageHover?.localFile?.childImageSharp?.fixed.src && <img className="double-image__image-hover" src={leftImageHover.localFile.childImageSharp.fixed.src} alt="Seiva" /> }
        </div>
        <div className='double-image__image'>
          { rightImage?.localFile?.childImageSharp?.fixed.src && <img className="double-image__image-main" src={rightImage.localFile.childImageSharp.fixed.src} alt="Seiva" /> }
          { rightImageHover?.localFile?.childImageSharp?.fixed.src && <img className="double-image__image-hover" src={rightImageHover.localFile.childImageSharp.fixed.src} alt="Seiva" /> }
        </div>
      </div>
    </section>
  )
}

DoubleImage.defaultProps = {
  leftImage: null,
  rightImage: null,
  leftImageHover: null,
  rightImageHover: null,
}

export default DoubleImage
