import React, { Component } from 'react'

class BasicContent extends Component {

  render() {
    let { title, content } = this.props
    return (
      <section className="basic">
        <div className="basic_inner container">
          <div className="basic__row"> 
            <h1 className="basic__title">{title}</h1>
            <div className="basic__content" dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </div>
      </section>
    )
  }
}

BasicContent.defaultProps = {
  title: `Privacy Policy`,
  content: `<p>Some people work to live. Others live to work. We work to effect positive change in the lives  of our clients. Because we genuinely care about making them, happier. Not just wealthier.</p><p>We’re proud to be holistic Business Advisors. And, we’re proud to believe that achieving a happier mindset involves: Finding purpose in what we do: to motivate and provide us with meaning. Fostering personal well-being: to control and balance out the stress that life throws at us. And formulating profits: to enable us with freedom to do what we desire.</p>`,
}

export default BasicContent