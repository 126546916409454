import React from 'react'

import { Financial, Business, Accounting, Talks } from '../components/icons'

const RenderIcon = (param) => {
  let icon = {
    'Financial': () => (<Financial />),
    'Business': () => (<Business />),
    'Accounting': () => (<Accounting />),
    'Talks': () => (<Talks />),
  }[param]

  if (!icon) return

  return icon()
}

export default RenderIcon