import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class TeamCard extends Component {

  render() {
    let { title, acf } = this.props
    return (
      <div className="team__item">
        <Fade bottom distance="40px">
          <div className="team__image">
            { acf.image && <img className="team__image-first" src={acf.image.localFile.childImageSharp.fixed.src} alt="Seiva - Our Team" /> } 
            { acf.rolloverImage && <img className="team__image-rollover" src={acf.rolloverImage.localFile.childImageSharp.fixed.src} alt="Seiva - Our Team" /> } 
          </div>
          <div className="team__wrap">
            <h4>{title}</h4>
            <span>{acf.position}</span>
            <a href={acf.linkedin} rel="noopener noreferrer" target="_blank">LinkedIn</a>
            <div className="team__bio" dangerouslySetInnerHTML={{ __html: acf.introduction }} />
          </div>
        </Fade>
      </div>
    )
  }
}

export default TeamCard