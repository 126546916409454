import React, { Component } from "react"
import { graphql } from "gatsby"
import he from "he"

import Seo from "../components/seo"

import RenderBlock from "../utils/render-block"

class PageTemplate extends Component {
  render() {
    let page = this.props.data.wpPage
    return (
      <>
        <Seo
          title={he.decode(page.seo.title)}
          bodyClass={page.slug}
          description={page.seo.metaDesc}
        />
        {page.acf.contentBlocks &&
          page.acf.contentBlocks.map((el, i) => {
            return RenderBlock(el.fieldGroupName, el, i)
          })}
      </>
    )
  }
}

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      slug
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
            }
          }
        }
      }
      acf {
        contentBlocks {
          ... on WpPage_Acf_ContentBlocks_Introduction {
            fieldGroupName
            title
            scroll
            subtitle
            image {
              localFile {
                childImageSharp {
                  fixed(width: 1200) {
                    src
                  }
                }
              }
            }
            column1
            column2
            buttonText
            buttonLink
          }
          ... on WpPage_Acf_ContentBlocks_BasicPage {
            fieldGroupName
            title
            content
          }
          ... on WpPage_Acf_ContentBlocks_Contact {
            fieldGroupName
            title
            content
            findUs
            chatToUs
          }
          ... on WpPage_Acf_ContentBlocks_Services {
            fieldGroupName
            title
            subtitle
            services {
              ... on WpService {
                title
                slug
                acf {
                  icon
                  shortTitle
                  shortDescription
                }
              }
            }
          }
          ... on WpPage_Acf_ContentBlocks_Instagram {
            fieldGroupName
            title
            backgroundColour
          }
          ... on WpPage_Acf_ContentBlocks_Cta {
            fieldGroupName
            title
            buttonText
            buttonLink
          }
          ... on WpPage_Acf_ContentBlocks_DoubleImage {
            fieldGroupName
            leftImage {
              localFile {
                childImageSharp {
                  fixed(width: 1000) {
                    src
                  }
                }
              }
            }
            rightImage {
              localFile {
                childImageSharp {
                  fixed(width: 1000) {
                    src
                  }
                }
              }
            }
            leftImageHover {
              localFile {
                childImageSharp {
                  fixed(width: 1000) {
                    src
                  }
                }
              }
            }
            rightImageHover {
              localFile {
                childImageSharp {
                  fixed(width: 1000) {
                    src
                  }
                }
              }
            }
          }
          ... on WpPage_Acf_ContentBlocks_DownloadGuide {
            fieldGroupName
            title
            content
            image {
              localFile {
                childImageSharp {
                  fixed(width: 1000) {
                    src
                  }
                }
              }
            }
          }
          ... on WpPage_Acf_ContentBlocks_OurWhy {
            fieldGroupName
            ourWhyTitle
            ourWhyContent
            image {
              localFile {
                childImageSharp {
                  fixed(width: 1200) {
                    src
                  }
                }
              }
            }
            ourHowTitle
            ourHowContent
            ourWhatTitle
            ourWhatContent
          }
          ... on WpPage_Acf_ContentBlocks_ImageGallery {
            fieldGroupName
            images {
              localFile {
                childImageSharp {
                  fixed(width: 1000) {
                    src
                  }
                }
              }
            }
          }
          ... on WpPage_Acf_ContentBlocks_Team {
            fieldGroupName
            title
            content
            team {
              ... on WpTeam {
                title
                acf {
                  image {
                    localFile {
                      childImageSharp {
                        fixed(width: 604) {
                          src
                        }
                      }
                    }
                  }
                  rolloverImage {
                    localFile {
                      childImageSharp {
                        fixed(width: 604) {
                          src
                        }
                      }
                    }
                  }
                  position
                  linkedin
                  introduction
                }
              }
            }
          }
          ... on WpPage_Acf_ContentBlocks_Testimonials {
            fieldGroupName
            title
            subtitle
            testimonials {
              ... on WpTestimonial {
                acf {
                  image {
                    localFile {
                      childImageSharp {
                        fixed(width: 900) {
                          src
                        }
                      }
                    }
                  }
                  quote
                  name
                  website
                  websiteLink
                  backgroundColour
                }
              }
            }
          }
          ... on WpPage_Acf_ContentBlocks_FeedbackForm {
            fieldGroupName
            title
          }
        }
      }
    }
  }
`

export default PageTemplate
