import React from "react"

import Contact from "../components/contact"
import Cta from "../components/cta"
import Instagram from "../components/instagram"
import Services from "../components/services"
import BasicPage from "../components/basic-content"
import OurWhy from "../components/our-why"
import Team from "../components/team"
import Intro from "../components/intro"
import Testimonials from "../components/testimonials"
import DownloadGuide from "../components/download-guide"
import ImageGallery from "../components/image-gallery"
import DoubleImage from "../components/double-image"
import FeedbackForm from "../components/feedback-form"

const RenderBlock = (param, el, i) => {
  let block = {
    page_Acf_ContentBlocks_Contact: (el, i) => <Contact {...el} key={i} />,
    page_Acf_ContentBlocks_Instagram: (el, i) => <Instagram {...el} key={i} />,
    page_Acf_ContentBlocks_Cta: (el, i) => <Cta {...el} key={i} />,
    page_Acf_ContentBlocks_Services: (el, i) => <Services {...el} key={i} />,
    page_Acf_ContentBlocks_BasicPage: (el, i) => <BasicPage {...el} key={i} />,
    page_Acf_ContentBlocks_OurWhy: (el, i) => <OurWhy {...el} key={i} />,
    page_Acf_ContentBlocks_Team: (el, i) => <Team {...el} key={i} />,
    page_Acf_ContentBlocks_Introduction: (el, i) => <Intro {...el} key={i} />,
    page_Acf_ContentBlocks_Testimonials: (el, i) => (
      <Testimonials {...el} key={i} />
    ),
    page_Acf_ContentBlocks_DownloadGuide: (el, i) => (
      <DownloadGuide {...el} key={i} />
    ),
    page_Acf_ContentBlocks_ImageGallery: (el, i) => (
      <ImageGallery {...el} key={i} />
    ),
    page_Acf_ContentBlocks_FeedbackForm: (el, i) => (
      <FeedbackForm {...el} key={i} />
    ),
    page_Acf_PostBlocks_DoubleImage: (el, i) => <DoubleImage {...el} key={i} />,
  }[param]

  if (!block) return

  return block(el, i)
}

export default RenderBlock
