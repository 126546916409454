import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import { Arrow } from './icons'
import { StaticQuery, graphql } from 'gatsby'

class Instagram extends Component {

  shouldComponentUpdate() {
    return false
  }

  render() {
    const { title, backgroundColour, data } = this.props

    return (
      <section className='instagram' style={{backgroundColor: backgroundColour}}>
        <div className='container'>
          <Fade bottom distance='40px'>
            <div className='instagram__title'>
              <p>{title}</p>
              <p><a href="https://www.instagram.com/seivabusiness/" target="_blank" rel="noopener noreferrer">Follow us <Arrow /></a></p>
            </div>
          </Fade>
          <div className='instagram__feed'>
            { data.allWpInstagram.nodes?.map((el, i) => (
              <div className='instagram__item' key={i}>
                <a href="https://www.instagram.com/seivabusiness/" target="_blank" rel="noopener noreferrer"><img src={el.featuredImage?.node?.localFile.childImageSharp.fixed.src} alt={el.title} /></a>
              </div>
            )) }
          </div>
        </div>
      </section>
    )
  }
}

const InstagramFeed = (props) => (
  <StaticQuery
    query={graphql`
      query {
        allWpInstagram(limit: 4) {
          nodes {
            title
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    fixed(width: 600) {
                      src
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Instagram data={data} {...props} />}
  />
)

export default InstagramFeed

